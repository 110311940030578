<template>
  <section
    id="footer"
    :class="{
      'bg-primary': 'primary',
      'bg-secondary': 'secondary',
      'bg-white': 'white',
    }"
  >
    <div class="row">
      <div class="address">
        <h1>CNai Accelerator</h1>
        <p>
          CNai Accelerator is not just a program; it's a transformative journey.
          Focused on Cambodia and led by Emerging Markets Entrepreneurs (EME) in
          collaboration with Village Capital, this gender-inclusive initiative
          is backed by international development organizations. This 10-month
          tailored mentorship offers more than guidance – it opens doors to a
          potential grant of up to USD 30,000, providing a gateway to a vast
          network of investors and a thriving entrepreneurial landscape.
        </p>
      </div>
      <div class="contact">
        <h3>Contact Us</h3>
        <p>cnaiaccelerator@eme.asia</p>
      </div>
      <div class="parthner">
        <p>Website By</p>
        <p><a href="https://www.binarylab.io">Binary Lab</a></p>
      </div>
      <span class="copy">© 2022 CNai Accelerator. All rights reserved.</span>
    </div>
    <div class="footer-image-background"></div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/footer.scss";
</style>
